nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .brand-container {
      .brand {
        img {
          height: 100%;
        }
      }
      .toggle-container {
        display: none;
        .toggle {
        }
        .mode {
        }
      }
    }
    .links-container {
      .links {
        list-style-type: none;
        display: flex;
        gap: 4rem;
        li {
          .dark {
            color: black;
          }
          .light {
            color: yellow;
          }
          a {
            color: var(--accent-color3);
            text-decoration: none;
          }
          &:last-of-type {
            a {
              color: var(--pink);
            }
          }
        }
      }
    }
  }
  
  [data-theme="light"] {
    nav {
      .brand-container {
        .brand {
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    nav {
      position: relative;
      padding: 1rem 2rem;
      .brand-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .brand {
          img {
            height: 1.5rem;
          }
        }
        .toggle-container {
          display: block;
          color: var(--accent-color1);
          display: flex;
          flex-direction: row-reverse;
          gap: 1rem;
          z-index: 40;
          .toggle {
            z-index: 40;
            display: block;
          }
        }
      }
      .links-container {
        z-index: 30;
        background-image: linear-gradient(101deg, var(--pink), var(--orange));
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        width: 0;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease-in-out;
        .links {
          flex-direction: column;
          li {
            a {
              color: var(--background);
            }
            &:last-of-type {
              display: none;
            }
          }
        }
      }
      .nav-visible {
        width: 60vw;
        visibility: visible;
        opacity: 1;
      }
    }
  }