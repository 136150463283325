.scrollToTop {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
    z-index: 100;
    position: fixed;
    bottom: 5%;
    right: 5%;
    border-radius: 5rem;
    background-color: red;
    height: 4rem;
    width: 4rem;
    border: solid 3px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, var(--pink), var(--orange));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--background) inset;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      box-shadow: none;
    }
    a {
      svg {
        font-size: 2rem;
        color: var(--accent-color1);
      }
    }
  }
  
  .visible {
    visibility: visible;
    opacity: 1;
  }