footer{
    margin: 6rem 0;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .upper{
        display: flex;
        justify-content: space-between;
        color: var(--accent-color3);
        .brand-container{
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .brand{
                img{

                }
            }
            p{

            }
            ul{
              list-style-type: none;
              display: flex;
              gap: 1rem;
              li {

              }
            }

        }
        .links{
            display: flex;
            gap: 8rem;
            .link{
                display: flex;
                flex-direction: column;
                gap: 2rem;
                h4 {
                    color: var(--accent-color1);
                    text-transform: uppercase;
                }
                ul {
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    li {

                    }
                }
            }
        }
    }

    .lower{
        display: flex;
        justify-content: space-between;
        span{
            color: var(--accent-color3);

        }
    }

}

[data-theme="light"] {
    footer {
      .brand-container {
        .brand {
          img {
            filter: brightness(0);
          }
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    footer {
      margin: 0;
      gap: 2rem;
      padding: 1rem 2rem;
      .upper {
        flex-direction: column;
        gap: 2rem;
        .links {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 2rem;
          .link {
            gap: 1rem;
          }
        }
      }
      .lower {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }