.releases{
    margin: 5rem 0 ;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .release {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 0.5rem;
        .content{
            padding: 5rem 8rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .title{
                font-size: 2rem;

            }
            .description  {
                font-size: 1.3rem;
                a {
                    color: black;
                    font-weight: bold;
                }
            }
            .link {
                color: black;
                font-weight: bold;
                text-decoration: none;
                display: flex;
                gap:1rem;
                svg {
                    font-size: 1.2rem;
                }
            }
            
        }

        .image{
            position: relative;
            overflow: hidden;
            // text-align: center;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            img{
                height: 24rem;
            }
            .ellipse {
                z-index: -1;
                position: absolute;
                bottom: -40%;
                right: 0;
                height: 20rem;
                width: 20rem;
                filter: blur(100px);
            }
            .pink {
                background-color: var(--pink);
              }
        }

        .card-container{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            overflow: hidden;
            .card-image{
                img{
                    height: 14rem;
                }
            }
            .ellipse {
                z-index: -1;
                position: absolute;
                bottom: -45%;
                left:0;
                height: 20rem;
                width: 20rem;
                filter: blur(100px);
            }
            .orange{
                background-color: var(--orange);

            }

        }

    }

    .orange {
        background-color: var(--orange);

    }
    .green{
        background-color: var(--green);

    }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .releases {
      margin: 0;
      gap: 0;
      .release {
        border-radius: 0;
        grid-template-columns: 1fr;
        padding: 1rem 2rem;
        gap: 2rem;
        &:nth-of-type(1) {
          .image {
            grid-row: 1;
          }
        }
        .content {
          padding: 0;
        }
        .image {
          zoom: 0.5;
        }
        .card-container {
          zoom: 0.5;
        }
        .ellipse {
          display: none !important;
        }
      }
    }
  }